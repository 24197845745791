.App { height: 100vh; width: 100vw; max-width: calc(110vh / var( --screen-convert-ratio ) ); overflow: hidden; margin: 0; }

.App.regular-mode { }

.App.regular-mode .main-content,
.App.regular-mode .search-content { }

.App.regular-mode .main-content { opacity: 1; pointer-events: all; height: auto; }
.App.regular-mode .search-content { opacity: 0; pointer-events: none; height: 0; position: relative; top: 0; }

.App.search-mode { background: #2a2a2a; }

.App.search-mode .main-content { opacity: 0; pointer-events: none; height: 0; }
.App.search-mode .search-content { opacity: 1; pointer-events: all; position: absolute; top: 8vh; width: 100%; }


.App.ws-error { background: #2a2a2a; }
.App.ws-error .vertical-flex { opacity: 0.2; pointer-events: none; }
.App.ws-error .vertical-flex * { pointer-events: none; }

.error { background: #fff; width: 50%; z-index: 99999; padding: 4vh; font-size: calc( 20vh * var( --font-convert-ratio ) ); line-height: calc( 24vh * var( --font-convert-ratio ) );  }
.error h3 { margin-bottom:  calc( 10vh * var( --font-convert-ratio ) ); }
.error p { margin-bottom: calc( 15vh * var( --font-convert-ratio ) ); }
.error p + p { position: relative; margin-bottom: calc( 10vh * var( --font-convert-ratio ) ); top: calc( -10vh * var( --font-convert-ratio ) ); opacity: 0.6; }

.cnt-info { position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: rgba(12,12,12,0.8); z-index: 99999; border: 5vh solid #fff;  padding: 6vh; }
.cnt-info ul { font-size: calc( 18vh * var( --font-convert-ratio ) ); line-height: calc( 26vh * var( --font-convert-ratio ) );}
.cnt-info ul li { color: #fff; }
.cnt-info ul li span { color: lime; }